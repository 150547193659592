import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Operador} from "../../../../core/model/operadores/operador";
import {OperadoresListActionsConfig, OperadoresListadoTableConfig} from "./operadores-listado.table-config";
import {Pageable} from "../../../../core/model/core/pageable";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {Page} from "../../../../core/model/core/pages/page";
import {NavigationService} from "../../../../core/services/navigation.service";
import {ModalService} from "../../../../core/services/components/modal.service";

@Component({
  selector: 'app-operadores-listado',
  templateUrl: './operadores-listado.component.html',
  styleUrls: ['./operadores-listado.component.scss']
})
export class OperadoresListadoComponent extends Page implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  protected modalSvc: ModalService;
  protected navService: NavigationService;
  protected operadoresSvc: OperadoresHttpService;

  protected data: Pageable<Operador>;
  protected columnsConfig = OperadoresListadoTableConfig;
  protected actionConfig = OperadoresListActionsConfig;
  protected page: number = 1;
  protected sort: string = "idOperador";
  protected order: "ASC" | "DESC" = "ASC";
  protected operador;

  protected formGroup: FormGroup;
  protected filtros: object;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              injector: Injector) {
    super();
    this.data = new Pageable<Operador>();
    this.filtros = {};

    this.navService = injector.get(NavigationService);
    this.modalSvc = injector.get(ModalService);
    this.operadoresSvc = injector.get(OperadoresHttpService);

    this.formGroup = new FormGroup({
      'idOperador': new FormControl(null, [Validators.pattern(/^[0-9]{1,5}$/)]),
      'tipoOperador': new FormControl(null),
      'empresa': new FormControl(null),
      'fechaAltaDesde': new FormControl(null),
      'fechaAltaHasta': new FormControl(null),
      'nif': new FormControl(null),
      'nombre': new FormControl(null),
      'primerApellido': new FormControl(null),
      'segundoApellido': new FormControl(null),
      'comarca': new FormControl(null),
      'municipio': new FormControl(null),
      'codigoPostal': new FormControl(null),
      'operadoresActivos': new FormControl(null),
      'operadoresInactivos': new FormControl(null),
    });
    this.navService.currentTabIndex = 0;
  }

  async ngOnInit() {
    this.page = 1;
    this.sort = "idOperador";
    this.order = "ASC";
    this.data = await lastValueFrom(this.operadoresSvc.listar(this.page, this.filtros, this.sort, this.order));
  }

  protected async onPageChange(page: number) {
    this.page = page;
    this.data = await lastValueFrom(this.operadoresSvc.listar(this.page, this.filtros, this.sort, this.order));
  }

  protected onAction($event: any) {
    if ($event.action == "view") {
      this.router.navigate([$event.item.numInscrito, "view"], {relativeTo: this.activatedRoute})
    }
    else if($event.action == "edit"){
      this.router.navigate([$event.item.numInscrito, "edit"], {relativeTo: this.activatedRoute})
    }
  }

  protected async onSort($event: any) {
    this.page = 1;
    this.sort = $event.field;
    this.order = $event.order;
    this.columnsConfig.forEach(c => c.order = "ASC")
    $event.order = this.order;
    this.data = await lastValueFrom(this.operadoresSvc.listar(this.page, this.filtros, this.sort, this.order));
  }


  protected async onSearch(value: object) {
    this.page = 1;
    this.filtros = value;
    this.data = await lastValueFrom(this.operadoresSvc.listar(this.page, this.filtros));
  }

  ngAfterViewInit() {
    this.formElement.nativeElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onSearch(this.formGroup.value);
        event.preventDefault();
      }
    });
  }

  protected onReset() {
    this.formGroup.reset();
    this.filtros = {};
    this.onSearch(this.filtros);
  }

}
