<app-chip-cards (viewChange)="this.changeView($event)">
    <app-chip-item>
        {{'OPERADORES.datosInscrito' | translate}}
    </app-chip-item>
    <app-chip-item>
        {{'OPERADORES.datosContacto' | translate}}
    </app-chip-item>
    <app-chip-item>
        {{'OPERADORES.datosInspeccion' | translate}}
    </app-chip-item>
    <app-chip-item>
        {{'OPERADORES.datosProduccion' | translate}}
    </app-chip-item>
</app-chip-cards>

<div [ngSwitch]="actualView">
    <div *ngSwitchCase="viewEnum.VIEW_GENERAL">
        <ng-container *ngTemplateOutlet="generalView"></ng-container>
    </div>
    <div *ngSwitchCase="viewEnum.VIEW_CONTACTO">
        <ng-container *ngTemplateOutlet="datosContacto"></ng-container>
    </div>
    <div *ngSwitchCase="viewEnum.VIEW_INSPECCION">
        <ng-container *ngTemplateOutlet="datosInspeccion"></ng-container>
    </div>
    <div *ngSwitchCase="viewEnum.VIEW_PRODUCCION">
        <ng-container *ngTemplateOutlet="datosProduccion"></ng-container>
    </div>
</div>

<button mat-icon-button (click)="onBack()" style="float: right; margin-right: 40px;">
    <mat-icon>arrow_back</mat-icon>
    {{"general.volver" | translate}}
</button>

<!-- VIEW GENERAL --->
<ng-template #generalView >
    <form [formGroup]="formInscrit">
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.datosGenerales' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row d-flex align-content-start" >
                    <div class="col-md-4 col-xl-2 order-1">
                        <app-input-text
                                [formControlName]="'numInscrito'"
                                [label]="'OPERADORES.numeroInscrito' | translate"

                        >
                        </app-input-text>
                    </div>

                    <div class="col-md-12 col-xl-6 order-2 order-md-4 order-xl-2">
                        <app-input-text
                                [formControlName]="'empresa'"
                                [label]="'general.empresa' | translate"
                        >

                        </app-input-text>
                    </div>

                    <div class="col-sm-6 col-md-4 col-xl-2 order-3 order-md-2 order-xl-3">
                        <app-input-text
                                [formControlName]="'cif'"
                                [label]="'general.cif' | translate"
                                [campoObligatorio]="true"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-md-4 col-xl-2 order-4 order-md-3 order-xl-4">
                        <app-input-datepicker
                                [formControlName]="'fechaAlta'"
                                [label]="'OPERADORES.fechaAlta' | translate"
                                [campoObligatorio]="true"
                        >

                        </app-input-datepicker>
                    </div>

                </div>
                <div class="row mt-1">
                    <div class="col-sm-3 col-lg-6 col-xl-2">
                        <app-input-text
                                [formControlName]="'nif'"
                                [label]="'general.nif' | translate"
                                [campoObligatorio]="true"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-sm-2 col-lg-6 col-xl-2 order-xl-last">
                        <app-input-select
                                [formControlName]="'idGenero'"
                                [label]="'general.genero' | translate"
                                [options]="generos | async | arrayToSelectItem:'idGenero':'descripcion'"
                                [campoObligatorio]="true"
                        >

                        </app-input-select>
                    </div>
                    <div class="col-sm-7 col-lg-12 col-xl-3">
                        <app-input-text
                                [formControlName]="'nombre'"
                                [label]="'general.nombre' | translate"
                                [campoObligatorio]="true"
                        >

                        </app-input-text>
                    </div>

                    <div class="col-sm-6 col-lg-6 col-xl-3">
                        <app-input-text
                                [formControlName]="'primerApellido'"
                                [label]="'general.primerApellido' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-6 col-xl-3">
                        <app-input-text
                                [formControlName]="'segundoApellido'"
                                [label]="'general.segundoApellido' | translate"
                        >

                        </app-input-text>
                    </div>
                </div>
            </mat-card-content>

        </mat-card>

        <mat-card class="mb-2 card shadow" >
            <mat-card-title>
                {{'general.direccionSocial' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <app-input-text
                                [formControlName]="'direccionSocial'"
                                [label]="'general.direccion' | translate"
                        >
                        </app-input-text>
                    </div>

                </div>

                <app-operadores-localizacion
                    [readonly]="!editing"
                    [municipioInput]="operador?.municipioSocial"
                    [campoObligatorio]="true"
                    (formValid)="municipioSocialValid = $event"
                >
                </app-operadores-localizacion>
            </mat-card-content>
        </mat-card>

        <mat-card class="mb-2 card shadow" >
            <mat-card-title>
                {{'general.direccionPostal' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <app-input-text
                                [formControlName]="'direccionPostal'"
                                [label]="'general.direccion' | translate"
                        >
                        </app-input-text>
                    </div>

                </div>

                <app-operadores-localizacion
                        [readonly]="!editing"
                        [municipioInput]="operador?.municipioPostal"
                        (formValid)="municipioPostalValid = $event"
                >
                </app-operadores-localizacion>
            </mat-card-content>
        </mat-card>

        <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!checkDadesInscritValid()"
                    (click)="onGuardarForm(formInscrit)"
            >
                {{'OPERADORES.modificarOperador.guardarDatosInscrito' | translate}}
            </button>
        </div>

    </form>

</ng-template>

<!-- VIEW Datos contacto --->
<ng-template #datosContacto>
    <form [formGroup]="formContacte">
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.datosContacto' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-6 col-lg-3 col-xl-2">
                        <app-input-text
                                [formControlName]="'telefono1'"
                                [label]="'general.telefono1' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-xl-2">
                        <app-input-text
                                [formControlName]="'telefono2'"
                                [label]="'general.telefono2' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-xl-2">
                        <app-input-text
                                [formControlName]="'movil'"
                                [label]="'general.movil' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-xl-2">
                        <app-input-text
                                [formControlName]="'fax'"
                                [label]="'general.fax' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-xl-2">
                        <app-input-text
                                [formControlName]="'movilNot'"
                                [label]="'general.movilNot' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-lg-12 col-xl-8">
                        <app-input-text
                                [formControlName]="'web'"
                                [label]="'general.web' | translate"
                        >

                        </app-input-text>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12 col-xl-6">
                        <app-input-text
                                [formControlName]="'email'"
                                [label]="'general.email' | translate"
                        >
                        </app-input-text>
                    </div>
                    <div class="col-md-12 col-xl-6">
                        <app-input-text
                                [formControlName]="'emailNot'"
                                [label]="'general.emailNot' | translate"
                        >

                        </app-input-text>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12 col-lg-6">
                        <app-input-text
                                [formControlName]="'facebook'"
                                [label]="'general.facebook' | translate"
                        >

                        </app-input-text>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <app-input-text
                                [formControlName]="'twitter'"
                                [label]="'general.twitter' | translate"
                        >

                        </app-input-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-input-checkbox
                                [formControlName]="'mostrarDatosDirectorio'"
                                [label]="'OPERADORES.permisoDatos' | translate"
                        >
                        </app-input-checkbox>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!formContacte.valid"
                    (click)="onGuardarForm(formContacte)"
            >
                {{'OPERADORES.modificarOperador.guardarDatosContacto' | translate}}
            </button>
        </div>
    </form>
</ng-template>

<!-- VIEW Datos inspección --->
<ng-template #datosInspeccion>
    <form [formGroup]="formInspeccio">
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.datosInspeccion' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <app-operadores-localizacion
                            [readonly]="!editing"
                            [enableProvincia]="false"
                            [enableInspector]="true"
                            [municipioInput]="operador?.municipioInspeccion"
                            (formValid)="formInspeccioValid = $event"
                    >
                    </app-operadores-localizacion>
                </div>
            </mat-card-content>
        </mat-card>

        <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!formInspeccioValid"
                    (click)="onGuardarForm(formInspeccio)"
            >
                {{'OPERADORES.modificarOperador.guardarDatosInspeccion' | translate}}
            </button>
        </div>
    </form>
</ng-template>

<!-- VIEW Datos producción --->
<ng-template #datosProduccion>
    <form [formGroup]="formProduccio">
        <mat-card class="mb-2 card shadow">
            <mat-card-title>
                {{'OPERADORES.datosProduccion' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-3 col-sm-6">
                        <app-input-select
                                formControlName="actividadPrincipal"
                                [label]="'OPERADORES.actPrincipal' | translate"
                                [options]="actividadesPrincipales | async | arrayToSelectItem:'idActividadPrincipal':'descripcion'"
                        >
                        </app-input-select>
                    </div>
                </div>
                <br/>
            </mat-card-content>
        </mat-card>

        <div *ngIf="editing" class="col-12 p-2 mt-2 d-flex justify-content-end">
            <button mat-raised-button color="basic" class="px-2" [disabled]="!formProduccio.valid"
                    (click)="onGuardarForm(formProduccio)"
            >
                {{'OPERADORES.modificarOperador.guardarDatosProduccion' | translate}}
            </button>
        </div>
    </form>
</ng-template>
