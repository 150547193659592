
<app-page-template
    [title]="'navbar.operadores' | translate"
>
    <mat-card class="card shadow mb-2">
        <form [formGroup]="formGroup" (ngSubmit)="onSearch(formGroup.value)" #formElement>
            <div class="row">
                <div class="col-sm-6 col-lg-2">
                    <app-input-text
                            [label]="'OPERADORES.id' | translate"
                            formControlName="idOperador"
                    >

                    </app-input-text>
                </div>

                <div class="col-sm-6 col-lg-2">
                    <app-input-text
                            [label]="'OPERADORES.tipoOperador' | translate"
                            formControlName="tipoOperador"
                    >

                    </app-input-text>
                </div>

                <div class="col">
                    <app-input-text
                            [label]="'general.empresa' | translate"
                            formControlName="empresa"
                    >

                    </app-input-text>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-lg-2">
                    <app-input-text
                            [label]="'general.nif' | translate"
                            formControlName="nif"
                    >

                    </app-input-text>
                </div>

                <div class="col-md-8 col-lg-3">
                    <app-input-text
                            [label]="'general.nombre' | translate"
                            formControlName="nombre"
                    >

                    </app-input-text>
                </div>

                <div class="col-md-6 col-lg-3">
                    <app-input-text
                            [label]="'general.primerApellido' | translate"
                            formControlName="primerApellido"
                    >

                    </app-input-text>
                </div>

                <div class="col-md-6 col-lg-3">
                    <app-input-text
                            [label]="'general.segundoApellido' | translate"
                            formControlName="segundoApellido"
                    >

                    </app-input-text>
                </div>
            </div>
            <div class="row">

                <div class="col-xl-3">
                    <app-input-text
                            [label]="'general.municipio' | translate"
                            formControlName="municipio"
                    >

                    </app-input-text>
                </div>
                <div class="col-sm-8 col-xl-3">
                    <app-input-text
                            [label]="'general.comarca' | translate"
                            formControlName="comarca"
                    >

                    </app-input-text>
                </div>

                <div class="col-sm-4 col-xl-2">
                    <app-input-text
                            [label]="'general.codigoPostal' | translate"
                            formControlName="codigoPostal"
                    >

                    </app-input-text>
                </div>

                <div class="col-md-6 col-lg-2 col-xl-2">
                    <app-input-datepicker
                            [label]="'OPERADORES.fechaAltaDesde' | translate"
                            [minDate]="{year: 1990, month:1, day: 1}"
                            [maxDate]="{year: 2024, month:1, day: 1}"
                            formControlName="fechaAltaDesde"
                    >

                    </app-input-datepicker>
                </div>
                <div class="col-md-6 col-lg-2 col-xl-2">
                    <app-input-datepicker
                            [label]="'OPERADORES.fechaAltaHasta' | translate"
                            [minDate]="{year: 1990, month:1, day: 1}"
                            formControlName="fechaAltaHasta"
                    >

                    </app-input-datepicker>
                </div>

            </div>
            <div class="row">
                <div class="col-md-6 col-lg-3">
                    <br/>
                    <p><strong>{{'OPERADORES.estado' | translate}}</strong></p>
                    <div style="display: inline;">
                            <app-input-checkbox
                                    [label]="'OPERADORES.activos' | translate"
                                    formControlName="operadoresActivos"
                            >
                            </app-input-checkbox>
                            <app-input-checkbox
                                    [label]="'OPERADORES.inactivos' | translate"
                                    formControlName="operadoresInactivos"
                            >
                            </app-input-checkbox>
                    </div>
                </div>

            </div>
            <div class="row">

            </div>
            <div class="col-12 p-2 mt-2 d-flex justify-content-end">
                <button mat-raised-button class="px-2 mx-2" (click)="onReset()">
                    {{'general.limpiar' | translate}}
                </button>
                <button mat-raised-button color="basic" class="px-2" (click)="onSearch(formGroup.value)">
                    {{'general.buscar' | translate}}
                </button>
            </div>

        </form>
    </mat-card>

    <mat-card class="card shadow">
        <app-generic-table
            [data]="data.content"
            [columnsConfig]="columnsConfig"
            [actionsConfig]="actionConfig"
            [page]="page"
            [paginated]="true"
            [collectionSize]="data.totalElements"
            [totalPages]="data.totalPages"
            (onPageChange)="onPageChange($event)"
            (onAction)="onAction($event)"
            (onSort)="onSort($event)"
        >

        </app-generic-table>
    </mat-card>

</app-page-template>
