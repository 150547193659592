import {Component, Injector, OnInit} from '@angular/core';
import {Operador} from "../../../../core/model/operadores/operador";
import {Page} from "../../../../core/model/core/pages/page";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {OperadoresHttpService} from "../../../../core/services/http/operadores-http.service";
import {Observable} from "rxjs";
import {Genero} from "../../../../core/model/operadores/genero";
import {GenerosService} from "../../../../core/services/http/generos.service";
import {Municipio} from "../../../../core/model/localizacion/municipio";
import {ActividadPrincipal} from "../../../../core/model/operadores/actividadPrincipal";

enum OperadorEditView {
  VIEW_GENERAL,
  VIEW_CONTACTO,
  VIEW_INSPECCION,
  VIEW_PRODUCCION
}

@Component({
  selector: 'app-operadores-detalle-general',
  templateUrl: './operadores-detalle-general.component.html',
  styleUrls: ['./operadores-detalle-general.component.scss']
})
export class OperadoresDetalleGeneralComponent extends Page implements OnInit {

  protected operadorSvc: OperadoresHttpService;
  protected generoSvc: GenerosService;

  public actualView = OperadorEditView.VIEW_GENERAL;
  public viewEnum = OperadorEditView;
  public action;
  protected formInscrit: FormGroup;
  protected formContacte: FormGroup;
  protected formInspeccio: FormGroup;
  protected formProduccio: FormGroup;

  protected operador: Operador;
  protected idOperador: string;
  protected editing = false;
  protected generos: Observable<Genero[]>
  protected municipio_aux = new Municipio();
  formInspeccioValid = false;
  municipioPostalValid: boolean;
  municipioSocialValid: boolean;

  protected actividadesPrincipales: Observable<ActividadPrincipal[]>

  constructor(private router: Router,
              protected activatedRoute: ActivatedRoute,
              injector: Injector) {
    super()
    this.idOperador = activatedRoute.snapshot.params['idOperador'];
    this.operadorSvc = injector.get(OperadoresHttpService);
    this.generoSvc = injector.get(GenerosService);

    this.formInscrit = new FormGroup({
      'numInscrito': new FormControl(this.operador?.numInscrito),
      'empresa': new FormControl('', Validators.maxLength(150)),
      'cif': new FormControl('', [this.formatoCIFValidator(), Validators.required]),
      'fechaAlta': new FormControl('', Validators.required),
      'nif': new FormControl('', [Validators.required, this.formatoNIFValidator()]),
      'idGenero': new FormControl('', Validators.required),
      'nombre': new FormControl('', [Validators.maxLength(30), Validators.required]),
      'primerApellido': new FormControl('', Validators.maxLength(30)),
      'segundoApellido': new FormControl('', Validators.maxLength(30)),
      'direccionSocial': new FormControl('', Validators.maxLength(60)),
      'direccionPostal': new FormControl('', Validators.maxLength(60))
    })

    this.formContacte = new FormGroup({
      'telefono1': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'telefono2': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'movil': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'fax': new FormControl('', [Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
      'web': new FormControl('', Validators.maxLength(70)),
      'email': new FormControl('', [Validators.maxLength(70), this.formatMailListValidator()]),
      'emailNot': new FormControl('', [Validators.maxLength(70), this.formatMailListValidator()]),
      'movilNot': new FormControl('', [Validators.maxLength(20), this.movilValidator()]),
      'facebook': new FormControl('', Validators.maxLength(200)),
      'twitter': new FormControl('', Validators.maxLength(100)),
      'mostrarDatosDirectorio': new FormControl(),
    })

    this.formInspeccio = new FormGroup({
      'inspector': new FormControl(''),
    })

    this.formProduccio = new FormGroup({
      'actividadPrincipal': new FormControl(null, Validators.required),
    });

  }

  private formatMailListValidator(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^((([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?);?\s*)+)?$/i;

      if(!value)
        return null;

      const emailListOK = pattern.test(value);

      return !emailListOK ? {emailListValid: true}: null;
    }
  }
  private formatoCIFValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^[A-Za-z]\d{8}$/;

      if(!value)
        return null;

      const CIFFormatOK = pattern.test(value);

      return !CIFFormatOK ? {CIFValid: true}: null;
    }
  }
  private formatoNIFValidator(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      const pattern =  /^[A-Za-z]?\d{8}[A-Za-z]$/;

      if(!value)
        return null;

      const NIFFormatOK = pattern.test(value);

      return !NIFFormatOK ? {NIFValid: true}: null;
    }
  }

  private movilValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\+?\d{1,19}$/.test(control.value);
      return valid ? null : { 'invalidMobileNumber': { value: control.value } };
    };
  }

  ngOnInit() {
    this.operadorSvc.getOperador(this.idOperador)
        .subscribe(op => {
          this.operador = op;
          this.formInscrit.patchValue(this.operador);
          this.formInspeccio.patchValue(this.operador);
          this.formContacte.patchValue(this.operador);
          this.formProduccio.patchValue(this.operador);
        })

    this.generos = this.generoSvc.getGeneros();
    this.actividadesPrincipales = this.operadorSvc.getActividadesPrincipales();

    this.activatedRoute.params.subscribe(params => {
      this.action = params['action'];

      if (this.action == 'edit') {
        this.editing = true;
        this.formInscrit.enable();
        this.formInscrit.get('numInscrito')?.disable();
        this.formContacte.enable();
        this.formInspeccio.enable();
        this.formInspeccio.get('inspector')?.disable();
        this.formProduccio.enable();
      }
      else if(this.action == 'view'){
        this.editing = false;
        this.formInscrit.disable();
        this.formContacte.disable();
        this.formInspeccio.disable();
        this.formProduccio.disable();
      }
    });

  }

  changeView($event: number) {
    this.actualView = $event;
  }

  onBack(){
    this.router.navigate(["../../"], {relativeTo: this.activatedRoute})
  }

  onGuardarForm(form: FormGroup) {
    //Patches the selected form into the operador
    const sharedKeys = Object.keys(this.operador).filter(key => key in form.controls);
    const values = sharedKeys.reduce((acc, key) => {
      acc[key] = form.controls[key].value;
      return acc;
    }, {} as Partial<Operador>);

    this.operador = {...this.operador, ...values};

    this.operadorSvc.modificarOperador(this.idOperador, this.operador).subscribe();
  }

  checkDadesInscritValid() {
    return this.formInscrit.valid && this.municipioPostalValid && this.municipioSocialValid;
  }
}
